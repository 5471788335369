<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="3">
        <!--  -->
        <v-card class="pa-4">
          <v-card-text v-if="client">
            <v-row>
              <v-col cols="12" class="pb-0">
                <strong>{{ $phraser.set('code:') }}</strong>
              </v-col>
              <v-col cols="12" class="pt-1">
                <span>{{ client.code }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <strong>{{ $phraser.set('updated at:') }}</strong>
              </v-col>
              <v-col cols="12" class="pt-1">
                <span>{{ formatTime(client.updated_at) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <strong>{{ $phraser.set('created at:') }}</strong>
              </v-col>
              <v-col cols="12" class="pt-1">
                <span>{{ formatTime(client.created_at) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <base-material-card color="blue">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light" color="white">
              {{ $phraser.set('edit details') }}
            </div>
          </template>
        </base-material-card>
        <v-form
          v-if="Object.keys(form).length"
          ref="form"
          @submit.prevent="submit"
        >
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" md="4">
                <base-smart-input
                  :model.sync="form.name"
                  :label="$phraser.set(`client's name`)"
                  min="3"
                  max="25"
                  :regex="new RegExp('^[a-zA-Z\\d\\s\\u0590-\\u05FF]+$')"
                  :regex-message="`form.client_name`"
                  :disabled="isSelf"
                />
              </v-col>

              <v-col cols="12" md="4">
                <base-smart-input
                  :model.sync="form.business_number"
                  :label="$phraser.set(`businessnumber`)"
                  length="9"
                  numberic
                  type="tel"
                  :disabled="isSelf"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="form.parent_client"
                  :items="suppliers"
                  item-text="name"
                  item-value="code"
                  :label="$t('supplier')"
                  :rules="supplierRules"
                  :disabled="isSelf"
                />
              </v-col>

              <v-col cols="12" md="6">
                <base-smart-input
                  :model.sync="form.contact_name"
                  :label="$phraser.set(`contactname`)"
                  min="3"
                  max="25"
                  required
                />
              </v-col>

              <v-col cols="12" md="6">
                <base-smart-input
                  :model.sync="form.contact_phone"
                  :label="$phraser.set(`phone`)"
                  min="9"
                  max="10"
                  numberic
                  required
                  type="tel"
                />
              </v-col>

              <v-col cols="12">
                <base-smart-input
                  :model.sync="form.address"
                  :label="$t('address')"
                  min="4"
                  max="50"
                />
              </v-col>

              <v-col cols="12" md="6">
                <base-smart-input
                  :model.sync="form.city"
                  :label="$t('city')"
                  :min="3"
                  :max="50"
                />
              </v-col>

              <v-col cols="12" md="6">
                <base-smart-input
                  :model.sync="form.postalcode"
                  :label="$phraser.set(`postalcode`)"
                  max="7"
                  min="5"
                  numberic
                  type="tel"
                />
              </v-col>
            </v-row>
            <v-col cols="12" class="text-right">
              <v-btn color="success" class="mr-0 text-h4" block type="submit">
                {{ $phraser.set('update details') }}
              </v-btn>
            </v-col>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ClientDetails',

  data: () => ({
    form: {},
    rules: {},
    initiated: false,
    loading: false,
    allowed_suppliers: [],
  }),

  computed: {
    self () {
      return this.$store.getters['clients/self']
    },
    client () {
      return this.$store.getters['clients/client'](this.$route.params.code)
    },
    clients () {
      return this.$store.getters['clients/clients']
    },
    suppliers () {
      return this.allowed_suppliers
    },
    supplierRules () {
      return [v => !!v || this.$t('form.required')]
    },
    isSelf () {
      return this.client.code === this.self.code
    },
  },

  watch: {
    client () {
      if (!Object.keys(this.form).length) {
        this.setForm().then(() => {
          this.$refs.form.resetValidation()
        })
      }
    },
  },

  created () {
    moment.locale(this.$i18n.locale)
  },

  mounted () {
    if (this.client) {
      this.setAllowedSuppliers()
      this.setForm().then(() => {
        this.$refs.form.resetValidation()
      })
    }
  },

  methods: {
    extractSuppliers (clients) {
      for (const client of clients) {
        if (client.code !== this.$route.params.code) {
          if (client.clients && Object.keys(client.clients).length) {
            this.extractSuppliers(Object.values(client.clients))
          }
          this.allowed_suppliers.push(client)
        }
      }
    },
    setAllowedSuppliers () {
      this.allowed_suppliers.push(this.self)
      const clients = Object.values(this.clients)
      this.extractSuppliers(clients)
    },
    async setForm () {
      const params = [
        'code',
        'name',
        'business_number',
        'parent_client',
        'contact_name',
        'contact_phone',
        'address',
        'city',
        'postalcode',
      ]
      const form = {}
      for (const param of params) {
        form[param] = this.client[param] ?? ''
      }
      this.form = form
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.loading = !this.loading
        await this.$store.dispatch('clients/editClient', this.form)
        this.loading = !this.loading
        this.$router.push('/clients')
      }
    },
    formatTime (time) {
      return moment(time)._isValid ? moment(time).format('lll') : time
    },

    findAllowedSuppliers (allClients) {
      Object.keys(allClients).find((key, index) => {
        if (allClients[key].code != this.$route.params.code) {
          this.allowed_suppliers.push(allClients[key])
          if (allClients[key].clients) {
            this.findAllowedSuppliers(allClients[key].clients)
          }
        }
      })
    },
  },
}
</script>
